<template>
  <div class="forgotForm">
    <forgot-password-form />
  </div>
</template>

<script>
const ForgotPasswordForm = () => import("./components/ForgotPasswordForm");
export default {
  name: "LoginForgot",
  components: {
    ForgotPasswordForm
  }
};
</script>

<style lang="scss" scoped>
.forgotForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
